export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate Connection"])},
        "facts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Host information"])},
        "baseInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic information"])},
        "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network information"])},
        "disk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disk information"])},
        "ansible_machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPU Arch"])},
        "os": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operation System"])},
        "cpumem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPU/Mem"])},
        "no_cached_facts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no cached facts found, please click the Validate Connection button above."])}
      },
      "zh": {
        "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["验证连接"])},
        "facts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主机信息"])},
        "baseInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["基本信息"])},
        "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["网络信息"])},
        "disk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["磁盘信息"])},
        "ansible_machine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPU 架构"])},
        "os": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["操作系统"])},
        "cpumem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CPU/内存"])},
        "no_cached_facts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未找到该节点的缓存信息，请点击上方的 \"验证连接\" 按钮"])}
      }
    }
  })
}
